import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body,
  html {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
