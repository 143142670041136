import React from "react";
import Styled from "./styles";

const Logo: React.FC = ({}) => {
  return (
    <Styled.Logo to="/">
      <img src="/waynelincoln.svg" />
      <span>wayne</span>lincoln
    </Styled.Logo>
  );
};

export default Logo;
