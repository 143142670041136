import styled from "styled-components";

const BioBox = styled.section`
  position: relative;
  background-color: #eee;
  padding-top: 100px;
  /* background-image: url("/matrix.jpg"); */
`;

const Box = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 50px 100px 50px;
  margin-bottom: -150px;
  z-index: 2;
  font-size: 18px;
  line-height: 24px;
  border-top: 20px solid #d1503f;
  border-bottom: 20px solid #d1503f;

  @media screen and (max-width: 750px) {
    padding: 100px 20px 20px;
  }
`;

const Avatar = styled.img`
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.25);
  border: 3px solid #fff;
  top: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const Content = styled.div`
  position: relative;
  padding-top: 20px;
  font-weight: 300;

  a {
    color: #d1503f;
  }
`;

const SocialIcons = styled.div`
  position: relative;
  text-align: center;
  padding-top: 20px;

  svg {
    margin: 0 10px;
    color: #333;
  }
`;

export default {
  BioBox,
  Box,
  Avatar,
  Content,
  SocialIcons,
};
