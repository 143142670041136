import styled from "styled-components";

const Container = styled.div`
  position: relative;
  max-width: ${({ width }) => (width === "wide" ? "1200px" : "800px")};
  margin: 0 auto;
  padding: 0 20px;
`;

export default {
  Container,
};
