import React from "react";
import Styled from "./styles";

const Menu: React.FC = () => {
  const handleContactScroll = () => {
    const contactSection = document.getElementById("contact");

    contactSection.scrollIntoView({
      behavior: "smooth",
    });
    contactSection.focus();
  };
  return (
    <Styled.Menu>
      <Styled.ContactAnchor
        aria-label="Go to contact information"
        onClick={handleContactScroll}
      >
        Contact
      </Styled.ContactAnchor>
    </Styled.Menu>
  );
};

export default Menu;
