import React from "react";
import Container from "../Container";
import Styled from "./styles";

const Footer: React.FC = () => {
  const date = new Date();
  return (
    <Styled.Footer>
      <Container width="narrow">
        <Styled.Copyright>
          &copy; Wayne Lincoln {date.getFullYear()}
        </Styled.Copyright>
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
