import styled from "styled-components";

const Footer = styled.footer`
  position: relative;
  background-color: #333;
  color: #fff;
  padding: 200px 20px 50px;
  text-align: center;
`;

const Copyright = styled.span`
  font-size: 14px;
  color: #ccc;
`;

export default {
  Footer,
  Copyright,
};
