import React from "react";
import Logo from "../Logo";
import Menu from "../Menu";
import Styled from "./styles";

const Header: React.FC = () => {
  return (
    <Styled.Header>
      <Logo />
      <Menu />
    </Styled.Header>
  );
};

export default Header;
