import styled from "styled-components";
import { Link } from "gatsby";

const Menu = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
`;

const MenuItem = styled(Link)`
  position: relative;
  display: block;
  margin-left: 20px;
  color: #333;
`;

const ContactAnchor = styled.a`
  background: transparent;
  border: none;
  margin-left: 20px;
  color: #333;
  cursor: pointer;
`;

export default {
  Menu,
  MenuItem,
  ContactAnchor,
};
