import React from "react";
import Styled from "./styles";

interface ContainerProps {
  width: "wide" | "narrow";
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ width, children }) => {
  return <Styled.Container width={width}>{children}</Styled.Container>;
};

export default Container;
