import React from "react";
import {
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaEnvelope,
  FaLinkedin,
} from "react-icons/fa";
import Container from "../Container";
import Styled from "./styles";

const BioBox: React.FC = ({}) => {
  return (
    <Styled.BioBox>
      <Container width="narrow">
        <Styled.Box id="contact">
          <Styled.Avatar src="/wayne_lincoln.jpg" />
          <Styled.Content>
            <h2>Hi, I'm Wayne.</h2>
            <p>
              I am a developer at{" "}
              <a
                href="https://linktr.ee"
                rel="noopener noreferrer"
                aria-label="Link to Linktree"
              >
                Linktree
              </a>{" "}
              who is passionate about learning new technologies across all
              levels of the stack. This website acts as an outlet for me to
              share my learnings, as well as provide ideation of solutions to
              the problems we face while trying to deliver products for the
              modern web and beyond.
            </p>
            <p>
              Feel free to reach out if you have any requests, critiques,
              comments, suggestions, or even if you just want to say "Hey!" via
              the linked social channels or email below.
            </p>
            <p>See-ya amongst the 1's and 0's!</p>
          </Styled.Content>
          <Styled.SocialIcons>
            <a
              href="https://www.linkedin.com/in/wayne-lincoln/"
              rel="noopener noreferrer"
              aria-label="Link to Linkedin"
            >
              <FaLinkedin size="2em" />
            </a>
            <a
              href="https://github.com/wayne-lincoln"
              rel="noopener noreferrer"
              aria-label="Link to Github"
            >
              <FaGithub size="2em" />
            </a>
            <a
              href="https://twitter.com/WayneLincoln"
              rel="noopener noreferrer"
              aria-label="Link to Twitter"
            >
              <FaTwitter size="2em" />
            </a>
            <a
              href="https://instagram.com/waynelincoln"
              rel="noopener noreferrer"
              aria-label="Link to Instagram"
            >
              <FaInstagram size="2em" />
            </a>
            <a
              href="mailto:waynelincoln@gmail.com"
              rel="noopener noreferrer"
              aria-label="Send email using default mail client"
            >
              <FaEnvelope size="2em" />
            </a>
          </Styled.SocialIcons>
        </Styled.Box>
      </Container>
    </Styled.BioBox>
  );
};

export default BioBox;
