import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BioBox from "../components/BioBox";
import GlobalStyle from "../styles/global";
import { FaArrowUp } from "react-icons/fa";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("scroll", () => handleScroll());

    return () => document.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <div id="top">
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <BioBox />
      <Footer />
      <AnimatePresence>
        {scrolled && (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            style={{
              background: "#d1503f",
              cursor: "pointer",
              boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
              border: "none",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 999,
              color: "#fff",
            }}
          >
            <FaArrowUp />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Layout;
