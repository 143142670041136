import styled from "styled-components";
import { Link } from "gatsby";

const Logo = styled(Link)`
  position: relative;
  font-weight: 700;
  text-decoration: none;
  font-size: 24px;
  color: #000;
  display: flex;
  align-items: center;

  span {
    font-weight: 300;
    margin-left: 10px;
  }

  img {
    height: 50px;

    @media screen and (max-width: 768px) {
      height: 25px;
    }
  }
`;

export default {
  Logo,
};
